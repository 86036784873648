import React, { Component } from "react";
import { connect } from "react-redux";
import Layout from "./layout";
import {closeFilters} from '../state/app';

class LayoutController extends Component {
  // Purpose of this controller is to tell main Layout component window dimension
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0, pos: {} };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.updateWindowDimensions);
      
    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.updateWindowDimensions);
    }
  }

  updateWindowDimensions() {
    if (typeof window !== "undefined") {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
  }
  render() {
    return (
      <Layout
        vw={this.state.width}
        vh={this.state.height}
        children={this.props.children}
        currentPath={this.props.currentPath}
        title={this.props.title}
        description={this.props.description}
        image={this.props.image ? this.props.image : null}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  closeFilters: () => dispatch(closeFilters())
});

export default connect(null, mapDispatchToProps)(LayoutController);
