import React, { Component } from "react";
import { connect } from 'react-redux';
import { FaFacebook, FaTwitter, FaPrint } from "react-icons/fa";
import "./style.scss";

class SomeShare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      title: ""
    };
    this.printPage = this.printPage.bind(this);
  }

  componentDidMount() {
    const url = typeof window !== "undefined" ? window.location.href : "";
    this.setState({ url: url });
    this.setState({title: this.props.title})
  }

  printPage () {
    if (typeof window !== "undefined") {
      window.print();
    }
  }
  render() {

    const facebookUrl = `https://m.facebook.com/sharer/sharer.php?u=${
      this.state.url
    }`;
    const twitterUrl = `https://twitter.com/intent/tweet?url=${this.state.url}&text=${this.state.title}`;
    return (
      <div className="share">
        <div className="share--label">
        <h3>
        {this.props.language === 'fi' ? 'Jaa tai tulosta' : null}
        {this.props.language === 'sv' ? 'Dela eller skriv ut' : null}
        {this.props.language === 'en' ? 'Share or print' : null}
        </h3>
        </div>
        <div className="someIcons">
          <a
            href={facebookUrl}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Jaa Facebookissa"
          >
            <FaFacebook />
          </a>
          <a
            href={twitterUrl}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Jaa Twitterissä"
          >
            <FaTwitter />
          </a>
          <FaPrint onClick={this.printPage} />
        </div>
      
      </div>
    );
  }
}
export default connect(state => ({
  language: state.language.language
 }), null)(SomeShare);