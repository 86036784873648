import React from "react";
import "./style.scss";

const PageContent = ({ data, heading }) => {

  if(data) {
  data = data.replace(/<a/g, "<a target='_blank'");
  }
  return (
    <div className="pageContent">
      <div dangerouslySetInnerHTML={{ __html: data}}></div>
    </div>
    );
  }

export default PageContent;