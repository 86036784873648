import React from "react";
import { connect } from 'react-redux'
import { graphql, Link } from "gatsby";
import {changeLanguage} from "../state/language";
import PageContent from "../components/pageContent";
import LayoutController from "../components/LayoutController";
import SomeShare from "../components/someShare";
import * as R from "ramda";
import "./style.scss";
import { DomEvent } from "leaflet";

const Material = ({ data, location, language, dispatch }) => {
    const returnMaterial = (objects) => {
      if(objects.contentfulMaterial !== null) {
        return objects.contentfulMaterial;
      }
    }
    const page = returnMaterial(data);
    /*if(page.language !== language) {
      dispatch(changeLanguage(page.language));
    }*/

    const returnImg = (data) => {
      const img = data.image;
      if (img) {
        return (<img alt="" src={R.path(['url'], img.file)} alt={R.path(['description'], img)}/>);
      }
    }

      return (
        <LayoutController 
        currentPath={location.pathname} 
        title={page.name} 
        description={R.path(['lead', 'lead'], page)}
        image={R.path(['image', 'file', 'url'], page)}>
          <div className="page">
            <div className="pageContainer">
            <Link to="/" className="backLink">
            {language === 'fi' ? 'Takaisin karttanäkymään' : null}
            {language === 'sv' ? 'Tillbaka till kartvy' : null}
            {language === 'en' ? 'Back to map view' : null}
            </Link>
              <div className="leadContainer">
                <div className="textContainer">
                  <h1>{page.name} </h1>
                  <div className="pageLead"><PageContent data={R.path(['lead', 'childMarkdownRemark', 'html'], page)}></PageContent></div>
                </div>
                <div className="pageImg">
                  {returnImg(page)}
                </div>
              </div>
            
              <h2>{language === 'fi' && page.lajitteluohje ? 'Lajitteluohje' :null}{language === 'sv' && page.lajitteluohje ? 'Sorteringsanvisning' :null}{language === 'en' && page.lajitteluohje ? 'Sorting instructions' :null}</h2>

              <p className="redError">
                {language !== page.language && language === 'fi' ? 'Tämä lajitteluohjesivu on eri kielellä kuin valitsemasi kieli. Voit palata karttanäkymään ja valita kierrätettävän materiaalin omalla kiellelläsi.' : null}
                {language !== page.language && language === 'sv' ? 'Denna sida är på ett annat språk än det språk du väljer. Du kan återgå till kartvy och välja material med ditt eget språk.' : null}
                {language !== page.language && language === 'en' ? 'This page is in different language than the language you choose. You can return to map view and choose material with your own language.' : null}
              </p>
              <PageContent data={R.path(['lajitteluohje', 'childMarkdownRemark', 'html'], page)}></PageContent>
              
              <div className="boxContainer">
                <div className={(page.yes ? 'boxContent yContent' : '')}>
                    <h2>{page.language === 'fi' && page.yes ? 'Kyllä' :null} {page.language === 'sv' && page.yes ? 'Ja' :null} {page.language === 'en' && page.yes ? 'Yes' :null}</h2>
                    <PageContent data={R.path(['yes', 'childMarkdownRemark', 'html'], page)}></PageContent>
                </div>

                <div className={(page.no ? 'boxContent noContent' : '')}>
                    <h2>{page.language === 'fi' && page.no ? 'Ei' :null} {page.language === 'sv' && page.no ? 'Nej' :null} {page.language === 'en' && page.no ? 'No' :null}</h2>
                    <PageContent data={R.path(['no', 'childMarkdownRemark', 'html'], page)}></PageContent>
                </div>
              </div>
              
              <div className={(page.remember ? 'boxContent remContent' : '')}>
                  <h2>{page.language === 'fi' && page.remember ? 'Muista' :null} {page.language === 'sv' && page.remember ? 'Kom ihåg' :null} {page.language === 'en' && page.remember ? 'Remember' :null}</h2>
                  <PageContent data={R.path(['remember', 'childMarkdownRemark', 'html'], page)}></PageContent>
              </div>

              <h2>{page.language === 'fi' && page.kerayskuljetus ? 'Keräys ja kuljetus' :null} {page.language === 'sv' && page.kerayskuljetus ? 'Insamling och transport' :null} {page.language === 'en' && page.kerayskuljetus ? 'Collecting and transportation' :null}</h2>
              <PageContent data={R.path(['kerayskuljetus', 'childMarkdownRemark', 'html'], page)}></PageContent>


              <h2>{page.language === 'fi' && page.kasittely ? 'Käsittely' :null} {page.language === 'sv' && page.kasittely ? 'Hantering' :null} {page.language === 'en' && page.kasittely ? 'Handling' :null}</h2>
              <PageContent data={R.path(['kasittely', 'childMarkdownRemark', 'html'], page)}></PageContent>

              <h2>{page.language === 'fi' && page.hyodyntaminen ? 'Hyödyntäminen' :null} {page.language === 'sv' && page.hyodyntaminen ? 'Återvinning och återvändning' :null} {page.language === 'en' && page.hyodyntaminen ? 'Recycling and recovery' :null}</h2>
              <PageContent data={R.path(['hyodyntaminen', 'childMarkdownRemark', 'html'], page)}></PageContent>

              <h2>{page.language === 'fi' && page.lisatietoja ? 'Lisätietoa' :null} {page.language === 'sv' && page.lisatietoja ? 'Läs mera' :null} {page.language === 'en' && page.lisatietoja ? 'Learn more' :null}</h2>
              <PageContent data={R.path(['lisatietoja', 'childMarkdownRemark', 'html'], page)}></PageContent>
              <div className="grid-x">
                <div className="cell medium-6">
                  <h2>{page.language === 'fi' && page.vastuutoimijat ? 'Vastuutoimijat' :null} {page.language === 'sv' && page.vastuutoimijat ? 'Ansvariga' :null} {page.language === 'en' && page.vastuutoimijat ? 'Responsible organizers' :null}</h2>
                  <PageContent data={R.path(['vastuutoimijat', 'childMarkdownRemark', 'html'], page)}></PageContent>
                </div>
                <div className="cell medium-6">
                  <SomeShare title={page.name} />
                </div>
              </div>
            </div>
          </div>
        </LayoutController>
      );
    }
  
    export default connect(state => ({
      language: state.language.language
    }), null)(Material);

export const materialQuery = graphql`
  query currentMaterial ($id: String!) {
    contentfulMaterial (id: { eq: $id }){
        id
        slug
        language
        name
        code
        image {
          file {
            url
          }
          description
        }
        lead {
          childMarkdownRemark {
            html
          }
        }
        lajitteluohje {
          childMarkdownRemark {
            html
          }
        }
        yes {
          childMarkdownRemark {
            html
          }
        }
        no {
          childMarkdownRemark {
            html
          }
        }
        remember {
          childMarkdownRemark {
            html
          }
        }
        kerayskuljetus {
          childMarkdownRemark {
            html
          }
        }
        kasittely {
          childMarkdownRemark {
            html
          }
        }
        hyodyntaminen {
          childMarkdownRemark {
            html
          }
        }
        lisatietoja {
          childMarkdownRemark {
            html
          }
        }
        vastuutoimijat {
          childMarkdownRemark {
            html
          }
        }
    }
}
`;
